import languages from '../data/i18n/languages.json';
export default defineNuxtRouteMiddleware((to) => {
  const pathEndsWithSlash = to.path.endsWith('/');
  let toPath = `${to.path}`;
  let localeExist = false;
  // Redirect to the `de/` url if it's empty
  if (!to.path || '/' === to.path) {
    toPath = languages[0].locale + '/';
  }
  // Check if the URL contains a locale
  for (const language of languages) {
    if (to.path.startsWith(`/${language.locale}`)) {
      localeExist = true;
    }
  }
  // if xml do not add slash
  if (to.path.endsWith('.xml')) {
    return;
  }
  // Return if URL is correct
  if (localeExist && pathEndsWithSlash) {
    return;
  }
  // Append `/de/...` if URL doesn't have a locale
  if (!localeExist) {
    toPath = '/' + languages[0].locale + to.path;
  }
  // Add slash to the end of URL
  if (!pathEndsWithSlash) {
    toPath += '/';
  }

  const queryParams = [];
  for (const prop in to.query) {
    queryParams.push(`${prop}=${to.query[prop]}`);
  }
  if (queryParams.length > 0) {
    toPath += `?${queryParams.join('&')}`;
  }

  if (to.fullPath !== toPath) {
    return navigateTo(toPath, { redirectCode: 301 });
  }
});

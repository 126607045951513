import components_plugin_KR1HBZs4kY from "/app/apps/frontend/.nuxt/components.plugin.mjs";
import unhead_neSs9z3UJp from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import vueuse_head_polyfill_LVXLULr4zk from "/app/node_modules/nuxt/dist/head/runtime/plugins/vueuse-head-polyfill.js";
import router_VeD7Ba96AP from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_8FoqvPFTot from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import shopware_plugin_txRT4f0fRY from "/app/apps/frontend/.nuxt/runtime/shopware.plugin.mjs";
import chunk_reload_client_SeG0EjL5Ec from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import i18n_VfGcjrvSkj from "/app/apps/frontend/plugins/i18n.ts";
import sentry_client_shVUlIjFLk from "/app/apps/frontend/plugins/sentry.client.ts";
export default [
  components_plugin_KR1HBZs4kY,
  unhead_neSs9z3UJp,
  vueuse_head_polyfill_LVXLULr4zk,
  router_VeD7Ba96AP,
  prefetch_client_8FoqvPFTot,
  shopware_plugin_txRT4f0fRY,
  chunk_reload_client_SeG0EjL5Ec,
  i18n_VfGcjrvSkj,
  sentry_client_shVUlIjFLk
]
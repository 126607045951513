// composables/usePrice.ts
import { ref } from 'vue';
import { createSharedComposable } from '@vueuse/core';

function customUsePrice() {
  const currencyLocale = ref('');
  const currencyCode = ref('');

  const init = (params: any) => {
    _setCurrencyCode(params.currencyCode);
    _setLocaleCode('en-US');
  };

  function _setCurrencyCode(code: any) {
    currencyCode.value = code;
  }
  function _setLocaleCode(locale: any) {
    currencyLocale.value = locale;
  }

  function getFormattedPrice(value: any) {
    if (typeof value === 'undefined') {
      return '';
    }

    return new Intl.NumberFormat(currencyLocale.value || 'en-US', {
      style: 'currency',
      currency: currencyCode.value || 'EUR',
    }).format(+value.toString());
  }

  return {
    getFormattedPrice,
    init,
  };
}

export const usePrice = createSharedComposable(customUsePrice);

import { fetchNavigationElements } from '@/api/navigation/fetchAll';
import { Category } from '@shopware-pwa/types';
import { SocialMedia, Links } from '../types/footer';
import { useI18n } from 'vue-i18n';

export const navigationId = ref<string | undefined | null>();
const mainNavigation = ref<Category[] | undefined | null>([]);
const footerNavigation = ref<Category[] | undefined | null>([]);

/**
 * API fetch footer navigation,
 * set footerNavigation
 */
const setFooterNavigation = async (apiInstance: any) => {
  const response = await fetchNavigationElements({
    type: 'footer-navigation',
    depth: 2,
    apiInstance,
  });

  footerNavigation.value = response;
};

/**
 * API fetch main navigation,
 * set mainNavigation
 */
const setMainNavigation = async (apiInstance: any) => {
  const response = await fetchNavigationElements({
    type: 'main-navigation',
    depth: 2,
    apiInstance,
    withProductsCount: true,
  });

  mainNavigation.value = response;
};

export default () => {
  const { locale } = useI18n();

  return {
    mainNavigation: computed(() => mainNavigation.value),
    footerNavigation: computed(() => footerNavigation.value),

    getMainNavigation: async (isUpdate: boolean, apiInstance: any) => {
      if (
        mainNavigation.value?.length == 0 ||
        mainNavigation.value == null ||
        isUpdate
      ) {
        await setMainNavigation(apiInstance);
      }

      return mainNavigation.value;
    },

    /**
     * Returns links and social media for footer
     */
    getFooterCustomLinks: async (apiInstance: any) => {
      if (
        footerNavigation.value?.length == 0 ||
        footerNavigation.value == null
      ) {
        await setFooterNavigation(apiInstance);
      }

      let footerSocialMedia: SocialMedia[] | undefined = [];
      let footerBottomLinks: Links[] | undefined = [];

      footerNavigation?.value?.forEach((footerElement: Category) => {
        if (
          footerElement.customFields?.footer_functionality_deep ===
          'footer_social_media'
        ) {
          footerSocialMedia = footerElement?.children?.map((element) => {
            const result = {} as SocialMedia;

            result.id = element.id;
            result.internalLink = element.internalLink;
            result.externalLink = element.externalLink;
            result.media = { url: element.media?.url };
            result.name = element.translated.name;

            return result;
          });
        }

        if (
          footerElement.customFields?.footer_functionality_deep ===
          'footer_bottom_links'
        ) {
          footerElement?.children?.forEach((footerElement) => {
            footerBottomLinks = footerElement?.children?.map((element) => {
              const result = {} as Links;

              result.id = element.id;
              result.name = element.translated.name;
              result.seoUrl =
                '/' +
                locale.value +
                '/' +
                (element.seoUrls ? element.seoUrls[0].seoPathInfo : '');
              result.internalLink = element.internalLink;
              result.externalLink = element.externalLink;
              result.linkNewTab = element.linkNewTab;

              return result;
            });
          });
        }
      });

      return {
        footerBottomLinks,
        footerSocialMedia,
      };
    },
    /**
     * Returns footer categories which are not
     * flagged with footer_functionality_deep
     */
    getFooterCategories: async (isUpdate: boolean, apiInstance: any) => {
      if (
        footerNavigation.value?.length == 0 ||
        footerNavigation.value == null ||
        isUpdate
      ) {
        await setFooterNavigation(apiInstance);
      }

      return footerNavigation.value?.filter((element) => {
        if (
          JSON.stringify(element.customFields).indexOf(
            'footer_functionality_deep'
          ) == -1
        ) {
          const result =
            element.children?.map((child: any) => {
              let seoUrl = '';
              if (child.seoUrls) {
                seoUrl = `/${locale.value}/` + child.seoUrls[0]?.seoPathInfo;
              }

              let internalLink = null;
              if (child.internalLink) {
                internalLink = `/${locale.value}/` + child.internalLink;
              }

              return {
                ...child,
                translated: { name: child.translated.name },
                seoUrls: { 0: { pathInfo: seoUrl || undefined } },
                internalLink,
              };
            }) || null;

          element.children = result || null;

          return element;
        }
      });
    },
  };
};

import {
  useCart,
  useShopwareContext,
  useUser as coreUseUser,
} from '@shopware-pwa/composables-next';

export function useUser() {
  const coreFunctionality = coreUseUser();
  const { apiInstance } = useShopwareContext();
  const { refreshCart } = useCart();
  const { refreshSessionContext } = useSessionContext();
  async function login(params: {
    username: string;
    password: string;
    _grecaptcha_v3: string;
  }) {
    const resp = await apiInstance.invoke.post(
      '/store-api/account/login',
      params
    );
    const contextToken =
      resp.data['sw-context-token'] || resp.data['contextToken'];
    await refreshSessionContext();
    await refreshCart();
    return { contextToken };
  }

  return {
    ...coreFunctionality,
    login,
  };
}

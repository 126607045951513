<script setup lang="ts">
import { getSessionContext } from '@shopware-pwa/api-client';
import { SessionContext } from '@shopware-pwa/types';
import { useSeoContext } from '~/composables/useSeoContext';
import { useI18n as customI18n } from '~/composables/useI18n';
import { useI18n } from 'vue-i18n';
import { useSessionContext } from '@shopware-pwa/composables-next';

const { refreshSessionContext } = useSessionContext();

const { locale } = useI18n();
const route = useRoute();

/**
 * Init session context
 */
const { apiInstance } = useShopwareContext();

const { data: sessionContextData } = await useAsyncData(
  'sessionContext',
  async () => {
    return await getSessionContext(apiInstance);
  }
);

/**
 * Init session context
 */
useSessionContext(sessionContextData.value as SessionContext);

/**
 * Init breadcrumbs context
 */
useBreadcrumbs();

/**
 * Set default language value
 */
const { activeLanguage, switchLanguage } = customI18n();
if (activeLanguage.value) {
  switchLanguage(activeLanguage.value);
}

/**
 * Refresh session context for the client-side of app for the updating info about auth, session etc
 * Refresh info about wishlists on client-side of app
 * Refresh info about cart on client-side of app
 */

const { getWishlistProducts } = useWishlist();
const { refreshCart } = useCart();
const { initJentis } = useJentis();
const { sessionContext } = useSessionContext();
const sessionContextMaintenance: any = ref(sessionContext);

onMounted(async () => {
  if (
    sessionContextMaintenance.value?.extensions?.maintenance
      ?.isMaintenanceRequest &&
    !route.fullPath.includes('maintenance')
  ) {
    window.location.href = `/${locale.value}/maintenance/`;
    return;
  }
});
onBeforeMount(async () => {
  await refreshSessionContext();
  await refreshCart();
  try {
    await getWishlistProducts();
  } catch (error) {
    console.log(error);
  }
  initJentis();

  // set price options (en-US because of symbol before number € 1,00)
  const { init: initFormattedPrice } = usePrice();
  initFormattedPrice({
    currencyCode: sessionContextData.value?.currency?.isoCode || '',
    localeCode: 'en-US',
  });
});

// get main navigation
const { getMainNavigation } = useNavigation();
await getMainNavigation(false, apiInstance);

// Init the notifications ref variable
provide('swNotifications', ref([]));

const newsletterSubscribeCheck = ref(false);
provide('newsletterSubscribe', newsletterSubscribeCheck);

/**
 * Init custom SEO Meta context
 */
const parseSeo = async () => {
  const { ssrContext } = useNuxtApp();
  const path = ssrContext?.event?.node?.req?.headers?.host
    ? `http://${ssrContext?.event?.node?.req?.headers?.host}${route.fullPath}`
    : typeof window !== 'undefined'
      ? window.location.href
      : '';

  useHead(
    await useSeoContext(
      path,
      (await apiInstance.config.languageId) ?? activeLanguage.value?.id ?? '',
      apiInstance,
      locale.value
    )
  );
};

/**
 * Listen to the route change for the getting new SEO
 */

if (typeof window !== 'undefined') {
  parseSeo();
}
watch(route, () => parseSeo());

const router = useRouter();
const { isLoggedIn } = useUser();

// TODO: improve logic
router.beforeEach((to, from, next) => {
  if (isLoggedIn.value) {
    if (to.matched[0].name == 'locale-checkout-register') {
      router.push({ name: 'locale-checkout-confirm' });
    } else {
      next();
    }
  } else {
    next();
  }
});
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

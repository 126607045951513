import {
  getStoreNavigation,
  ShopwareApiInstance,
} from '@shopware-pwa/api-client';

const fetchNavigationElements = async (payload: {
  type: 'main-navigation' | 'footer-navigation';
  searchCriteria?: Record<string, boolean | string | number>;
  depth: number;
  apiInstance: ShopwareApiInstance;
  withProductsCount?: boolean;
}) => {
  try {
    const response = getStoreNavigation(
      {
        requestActiveId: payload.type,
        requestRootId: payload.type,
        depth: payload.depth,
        searchCriteria: {
          ...payload.searchCriteria,
          // new field provided in ticket https://sowhat.atlassian.net/browse/LSWS-478, as shopware interface not aware about it,
          // we need disable type checking for this line or extend interface
          // eslint-disable-next-line
          // @ts-ignore
          'with-products-count': payload.withProductsCount || false,
        },
      },
      payload.apiInstance
    );
    return response;
  } catch (error) {
    console.error('fetch navigation error', error);
  }
};

export { fetchNavigationElements };

import { ShopwareApiInstance } from '@shopware-pwa/api-client';
import { MaybeReadonlyRef, MaybeRef } from '@vueuse/shared';
import { ref } from 'vue';
import * as process from 'process';
import pickBy from 'lodash.pickby';
/**
 * Declare brand name
 */
const brandNameList = {
  legero: 'Legero Next',
  superfit: 'Superfit Next',
  think: 'Think Next',
} as Record<string, string>;

const defaultLogo =
  'https://drriyftn4oomd.cloudfront.net/media/d3/40/b4/1657618913/logo-legero.svg';

/**
 * DreiscSeoInstallmentRichSnippetData BE subscriber data structure
 */
interface IDreiscSeoInstallmentRichSnippetData {
  extensions: object[];
  ldJson: object[];
}

/**
 * DreiscSeoInstallmentRobotsTagData BE subscriber data structure
 */
interface IDreiscSeoInstallmentRobotsTagData {
  robotsTag: string;
}

/**
 * DreiscSeoInstallmentSocialMediaData BE subscriber data structure
 */
interface IDreiscSeoInstallmentSocialMediaData {
  extensions: object[];
  type: string;
  metaTitle: string | null;
  metaDescription: string | null;
  facebookTitle: string | null;
  facebookDescription: string | null;
  facebookImage: string | null;
  twitterTitle: string | null;
  twitterDescription: string | null;
  twitterImage: string | null;
}

/**
 * IDreiscSeoInstallmentCanonicalData BE subscriber data structure
 */
interface IDreiscSeoInstallmentCanonicalData {
  extensions: object[];
  rel: string;
  href: string;
  hreflang: string | null;
}

/**
 * IDreiscSeoInstallmentCategoryInformationData BE subscriber data structure
 */
interface IDreiscSeoInstallmentCategoryInformationData {
  extensions: object[];
  cmsType: string | null;
}

/**
 * General BE response data structure
 */
interface IServerResponse {
  dreiscSeoInstallmentRichSnippetData: IDreiscSeoInstallmentRichSnippetData | null;
  dreiscSeoInstallmentRobotsTagData: IDreiscSeoInstallmentRobotsTagData | null;
  dreiscSeoInstallmentSocialMediaData: IDreiscSeoInstallmentSocialMediaData | null;
  dreiscSeoInstallmentCanonicalData:
    | IDreiscSeoInstallmentCanonicalData[]
    | null;
  dreiscSeoInstallmentCategoryInformationData: IDreiscSeoInstallmentCategoryInformationData | null;
}

/**
 * Helper response data structure
 */
export interface ISeoContextResponse {
  titles: object;
  styles: MaybeReadonlyRef<object[]> | MaybeRef<object[]>;
  scripts: MaybeReadonlyRef<object[]> | MaybeRef<object[]>;
}

/**
 * Convert and build result title
 * @param data
 */
const compileTitle = (
  data: IServerResponse | undefined
): string | null | undefined => {
  return (
    data?.dreiscSeoInstallmentSocialMediaData?.facebookTitle ||
    data?.dreiscSeoInstallmentSocialMediaData?.metaTitle
  );
};

export const getProductMeta = (formattedPrice: string, brand: string) => {
  return {
    meta: [
      {
        property: 'product:brand',
        content: brand,
      },
      {
        property: 'product:price:amount',
        content: formattedPrice.slice(0, -2),
      },
      {
        property: 'product:price:currency',
        content: formattedPrice.slice(-1),
      },
      {
        property: 'product:product_link',
        content: typeof window !== 'undefined' && window.location.href,
      },
    ],
  };
};

/**
 * Convert and build result meta titles data
 * @param data
 * @param path
 */
const compileMeta = (
  data: IServerResponse | undefined,
  path: string
): object[] => {
  const metaTitle = data?.dreiscSeoInstallmentSocialMediaData?.metaTitle;
  const metaDescription =
    data?.dreiscSeoInstallmentSocialMediaData?.metaDescription;
  const brandName = brandNameList[process?.env?.BRAND || ''] || '';

  return [
    {
      name: 'title',
      content: metaTitle,
    },
    {
      name: 'description',
      content: metaDescription,
    },
    {
      property: 'og:type',
      content: data?.dreiscSeoInstallmentSocialMediaData?.type,
    },
    {
      property: 'og:site_name',
      content: brandName,
    },
    {
      property: 'og:title',
      content:
        data?.dreiscSeoInstallmentSocialMediaData?.facebookTitle || metaTitle,
    },
    {
      property: 'og:url',
      content: path,
    },
    {
      property: 'og:description',
      content:
        data?.dreiscSeoInstallmentSocialMediaData?.facebookDescription ||
        metaDescription,
    },
    {
      property: 'og:image',
      content:
        data?.dreiscSeoInstallmentSocialMediaData?.facebookImage || defaultLogo,
    },
    {
      name: 'twitter:site',
      content: brandName,
    },
    {
      name: 'twitter:card',
      content: data?.dreiscSeoInstallmentSocialMediaData?.type,
    },
    {
      name: 'twitter:title',
      content:
        data?.dreiscSeoInstallmentSocialMediaData?.twitterTitle || metaTitle,
    },
    {
      name: 'twitter:description',
      content:
        data?.dreiscSeoInstallmentSocialMediaData?.twitterDescription ||
        metaDescription,
    },
    {
      name: 'twitter:image',
      content:
        data?.dreiscSeoInstallmentSocialMediaData?.twitterImage || defaultLogo,
    },
    {
      name: 'robots',
      content:
        window.location.href.includes('search') ||
        window.location.href.includes('order') ||
        window.location.href.includes('properties')
          ? 'noindex,follow'
          : data?.dreiscSeoInstallmentRobotsTagData?.robotsTag ||
            'index,follow',
    },
  ].filter((item) => item.content);
};

/**
 * Convert and build result meta styles data
 * @param data
 * @param path
 */
const links = ref([]);
const compileLinks = (
  data: IServerResponse | undefined,
  path: string,
  locale: string
): MaybeReadonlyRef<object[]> | MaybeRef<object[]> => {
  const seoLinks = [] as never[];
  if (data?.dreiscSeoInstallmentCanonicalData) {
    data?.dreiscSeoInstallmentCanonicalData?.forEach(
      ({ href, hreflang, rel }) => {
        seoLinks.push(
          pickBy({
            // hid: 'canonical',
            rel,
            href,
            hreflang,
          }) as never
        );
      }
    );
  }

  // If not PLP (Product List Page)
  if (
    data?.dreiscSeoInstallmentCategoryInformationData &&
    data?.dreiscSeoInstallmentCategoryInformationData?.cmsType !==
      'product-list'
  ) {
    const robotsTagContent = data?.dreiscSeoInstallmentRobotsTagData?.robotsTag
      ?.split(',')
      .map((value: string) => value.trim());
    // If robotsTag contains 'index' then add canonical attribute to seo
    if (robotsTagContent?.includes('index')) {
      seoLinks.push(
        pickBy({
          // hid: 'canonical',
          rel: 'canonical',
          href: path,
        }) as never
      );
    }
  }

  if (path.endsWith(`${locale}/`)) {
    seoLinks.push(
      pickBy({
        // hid: 'canonical',
        rel: 'canonical',
        href: path,
      }) as never
    );
  }

  links.value = seoLinks;
  return links;
};

/**
 * Convert and build result meta scripts data
 * @param data
 */
const scripts = ref();
const compileScripts = (
  data: IServerResponse | undefined
): MaybeReadonlyRef<object[]> | MaybeRef<object[]> => {
  scripts.value = [
    {
      hid: 'application/ld+json',
      type: 'application/ld+json',
      children: JSON.stringify(
        data?.dreiscSeoInstallmentRichSnippetData?.ldJson || {}
      ),
    },
  ];

  return scripts;
};

/**
 * Helper for getting custom SEO data including MetaTags and Robots data based on the relevant page route.
 * Ideal to use as SSR
 */
export function useSeoContext(
  path: string,
  languageId: string,
  apiInstance: ShopwareApiInstance,
  locale: string
) {
  try {
    return fetch(`${apiInstance.config.endpoint}/store-api/context/seo`, {
      method: 'POST',
      body: JSON.stringify({ path }),
      headers: {
        'Content-Type': 'application/json',
        'sw-access-key': apiInstance.config.accessToken as string,
        'sw-language-id': languageId as string,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        return {
          htmlAttrs: { lang: locale },
          title: compileTitle(response?.data),
          meta: compileMeta(response?.data, path),
          link: compileLinks(response?.data, path, locale),
          script: compileScripts(response?.data),
        };
      });
  } catch (e) {
    console.error(e);
    return {
      title: compileTitle(undefined),
      meta: compileMeta(undefined, path),
      link: compileLinks(undefined, path, locale),
      script: compileScripts(undefined),
    };
  }
}

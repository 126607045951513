import { LocationQuery, LocationQueryValue } from 'vue-router';

declare global {
  interface Window {
    jentis: any;
    _jts: any;
  }
}

export const useJentis = () => {
  /**
   * initDatalayer fetches all information from backend,
   * this data are used later for puhsing to jentis
   * @param param0
   * @returns array of configured (backend) data we can push to jentis
   */
  const { apiInstance } = useShopwareContext();
  const { captureException } = useSentry();
  const initDataLayer = ({
    resourceType,
    navigationId,
    productId,
    orderId,
    cartItem,
    searchTerm,
  }: {
    resourceType: string;
    navigationId?: string;
    productId?: string;
    orderId?: string;
    cartItem?: Record<string, any>;
    searchTerm?: string | LocationQueryValue[] | LocationQueryValue;
  }) => {
    const load = async () => {
      try {
        const { data } = await apiInstance.invoke.post(
          '/store-api/jentis-tagmanager/render-route',
          {
            route: resourceType,
            navigationId,
            productId,
            orderId,
            cartItem,
            searchTerm,
          }
        );

        return data;
      } catch (error: any) {
        captureException(error, 'jentis');
      }

      return;
    };

    return { load };
  };

  const config = useRuntimeConfig();
  /**
   * Init jentis script
   * https://docs.jentis.com/docs/add-jentis-to-your-websitec
   * @returns
   */
  const initJentis = () => {
    if (typeof window === 'undefined') {
      return;
    }

    if (config.JENTIS_ANALYTICS_ENABLED === 'true') {
      const sCDNConfig = {
        sCDN: config.JENTIS_ANALYTICS_TRACKDOMAIN,
        sCDNProject: config.JENTIS_ANALYTICS_TRACKID,
        sCDNWorkspace: config.JENTIS_ANALYTICS_ENVIRONMENT,
        sCDNVers: config.JENTIS_ANALYTICS_VERSION,
      };

      if (
        window.localStorage !== null &&
        typeof window.localStorage === 'object' &&
        typeof window.localStorage.getItem === 'function' &&
        window.sessionStorage !== null &&
        typeof window.sessionStorage === 'object' &&
        typeof window.sessionStorage.getItem === 'function'
      ) {
        sCDNConfig.sCDNVers =
          window.sessionStorage.getItem('jts_preview_version') ||
          window.localStorage.getItem('jts_preview_version') ||
          sCDNConfig.sCDNVers;
      }
      window.jentis = window.jentis || {};
      window.jentis.config = window.jentis.config || {};
      window.jentis.config.frontend = window.jentis.config.frontend || {};
      window.jentis.config.frontend.cdnhost =
        sCDNConfig.sCDN +
        '/get/' +
        sCDNConfig.sCDNWorkspace +
        '/web/' +
        sCDNConfig.sCDNVers +
        '/';
      window.jentis.config.frontend.vers = sCDNConfig.sCDNVers;
      window.jentis.config.frontend.env = sCDNConfig.sCDNWorkspace;
      window.jentis.config.frontend.project = sCDNConfig.sCDNProject;
      window._jts = window._jts || [];

      //TODO: add error handling / sentry log?
      const f = document.getElementsByTagName('script')[0];
      const j = document.createElement('script');
      j.async = true;
      j.src =
        window.jentis.config.frontend.cdnhost + config.JENTIS_ANALYTICS_FILE;

      f?.parentNode?.insertBefore(j, f);
    }
    return;
  };

  /**
   * @param data
   */
  const pushEvents = (data: Array<any>) => {
    if (typeof window === 'undefined' || typeof data === 'undefined') {
      return;
    }

    window._jts = window._jts || [];

    data.forEach((item: any) => {
      if (Array.isArray(item)) {
        item.forEach((product: any) => window._jts.push(product));
      } else {
        window._jts.push(item);
      }
    });
  };

  return {
    initJentis,
    initDataLayer,
    pushEvents,
  };
};

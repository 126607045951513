import languagesFile from '@/data/i18n/languages.json';
import { ILanguageFileStruct } from '@/locales/utils/types';
import { useI18n as i18n } from 'vue-i18n';
import { Language } from '@shopware-pwa/types';
import { getSeoUrls } from '@shopware-pwa/api-client';
import { ComputedRef } from 'vue';

export const useI18n = () => {
  const { locale } = i18n();
  const { setLanguage } = useSessionContext();
  const { apiInstance } = useShopwareContext();
  const { currentRoute } = useRouter();

  // Get languages list
  const languages = languagesFile.map((language: ILanguageFileStruct) => {
    if (locale.value === language.locale) {
      apiInstance.update({ languageId: language.id });
    }

    return {
      ...language,
      selected: locale.value === language.locale,
    };
  });

  // Get active language
  const activeLanguage: ComputedRef<ILanguageFileStruct | undefined> = computed(
    () => languagesFile.find((language) => locale.value === language.locale)
  );

  // Switch app language
  const switchLanguage = (
    language: Partial<Language> | ILanguageFileStruct
  ) => {
    setLanguage(language as Partial<Language>);
    apiInstance.update({ languageId: language.id });
  };

  // Redirect to navigation route by locale
  const redirectByNavigation = async (
    navigationId: string | null | undefined,
    language: Partial<Language>
  ) => {
    let url = currentRoute.value.path.slice(4);
    if (navigationId) {
      const seoUrls = await getSeoUrls(
        navigationId,
        language.id as string,
        apiInstance
      );

      url = seoUrls.elements.length ? seoUrls.elements[0]?.seoPathInfo : '';
    }

    window.location.href =
      `/${language.locale}/${url}` + window.location.search;
  };

  // Check if browser language is not the same
  const browserLanguageMismatch = (): boolean => {
    const acceptedLanguage = useCookie('accepted_language');
    if (acceptedLanguage.value) {
      return false;
    }

    const browserLanguage = navigator?.languages
      ? navigator.languages[0]
      : null;

    const selectedLanguage = languages?.filter((language) => language.selected);
    return selectedLanguage && selectedLanguage[0].code !== browserLanguage;
  };

  // Accept browser language
  const acceptBrowserLanguage = (language: string) => {
    const acceptedLanguage = useCookie('accepted_language');
    acceptedLanguage.value = language;
  };

  return {
    languages,
    activeLanguage,
    switchLanguage,
    browserLanguageMismatch,
    acceptBrowserLanguage,
    redirectByNavigation,
  };
};

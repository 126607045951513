import { Event } from '@sentry/types';
import { ClientApiError } from '@shopware-pwa/types';

interface IHint {
  originalException?: unknown;
}

export function createBeforeSend(stage: string, pluginType: string) {
  return (event: Event, hint?: IHint): Event | null => {
    if (event.exception) {
      if (!['main', 'staging'].includes(stage)) {
        console.error(`[Exception handled by Sentry]:`, {
          event,
          hint,
        });
      }

      // extract the original exception from the hint object, if available
      const originalException = hint?.originalException;

      if (originalException) {
        if (!(originalException instanceof Error)) {
          // verify if the object has properties specific to a ClientApiError
          if (
            originalException.hasOwnProperty('messages') &&
            originalException.hasOwnProperty('statusCode')
          ) {
            const error = clientApiErrorToError(
              originalException as ClientApiError
            );

            event.exception = {
              values: [
                {
                  type: 'ClientApiError',
                  value: error.message,
                },
              ],
            };

            // add the original exception to the event's extra field for debugging purposes
            event.extra = {
              ...event.extra,
              __serialized__: originalException,
            };
          }
        }
      }
    }

    event.tags = {
      ...event.tags,
      'app-plugin': pluginType,
    };

    return event;
  };
}

const clientApiErrorToError = (error: ClientApiError): Error => {
  // create a new Error object with the first error detail or a default message
  return new Error(
    error.messages?.[0]?.detail || 'A client API error occurred'
  );
};

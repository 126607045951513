import * as Sentry from '@sentry/vue';
import { defineNuxtPlugin, useRouter, useRuntimeConfig } from '#app';
import { createBeforeSend } from '~/utils/sentryUtils';

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp } = nuxtApp;

  const config = useRuntimeConfig();
  const stage = config.public.STAGE;

  Sentry.init({
    release: `legero-frontends@${config.public.VERSION}`,
    app: [vueApp],
    dsn: config.public.SENTRY_DSN,
    environment: stage,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(useRouter()),
      }),
    ],
    sampleRate: ['main', 'staging'].includes(stage) ? 1.0 : 0.5,
    tracesSampleRate: ['main', 'staging'].includes(stage) ? 0.25 : 1.0,
    beforeSend: createBeforeSend(stage, 'client'),
  });

  vueApp.mixin(
    Sentry.createTracingMixins({
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update'],
    })
  );

  Sentry.attachErrorHandler(vueApp, {
    attachProps: true,
    logErrors: false,
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update'],
  });
});

import { createI18n } from 'vue-i18n';
import { watch } from 'vue';
import languages from '../data/i18n/languages.json';
import { navigationId } from '../composables/useNavigation';

const defaultLocale = 'de';
const getLocale = (route: any): string => {
  const locale = route?.params?.locale ? route.params.locale : defaultLocale;

  const language = languages.find((language) => language.locale === locale);
  return language?.locale ? language?.locale : defaultLocale;
};

export default defineNuxtPlugin(async ({ vueApp }) => {
  const route = useRoute();

  const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: getLocale(route),
    messages: await $fetch('/api/snippets/messages'),
  });

  watch(route, (route) => {
    i18n.global.locale.value = getLocale(route);
    navigationId.value = null;
  });

  vueApp.use(i18n);
});

import { default as _91_46_46_46all_93a3xWq08JtuMeta } from "/app/apps/frontend/pages/[...all].vue?macro=true";
import { default as addressguRCl4dvGPMeta } from "/app/apps/frontend/pages/account/address.vue?macro=true";
import { default as index3tD6t8gxyLMeta } from "/app/apps/frontend/pages/account/index.vue?macro=true";
import { default as orderUrWAIr8TRAMeta } from "/app/apps/frontend/pages/account/order.vue?macro=true";
import { default as paymentMrp2vORYZrMeta } from "/app/apps/frontend/pages/account/payment.vue?macro=true";
import { default as profiletdkuPhsJWrMeta } from "/app/apps/frontend/pages/account/profile.vue?macro=true";
import { default as index3LsJlrAiTcMeta } from "/app/apps/frontend/pages/account/recover/password/index.vue?macro=true";
import { default as confirm6LNl4L8oUZMeta } from "/app/apps/frontend/pages/checkout/confirm.vue?macro=true";
import { default as registerutssanLaQwMeta } from "/app/apps/frontend/pages/checkout/register.vue?macro=true";
import { default as indexKHYIFtlm2mMeta } from "/app/apps/frontend/pages/checkout/success/[id]/index.vue?macro=true";
import { default as indexpj0li1Bm2eMeta } from "/app/apps/frontend/pages/checkout/unpaid/[id]/index.vue?macro=true";
import { default as filialfinderu4TVgYw9fhMeta } from "/app/apps/frontend/pages/filialfinder.vue?macro=true";
import { default as healthchecksVfnRSYgEDMeta } from "/app/apps/frontend/pages/healthcheck.vue?macro=true";
import { default as loginz5xTTqtYoVMeta } from "/app/apps/frontend/pages/login.vue?macro=true";
import { default as logoutg1aYmOMNDyMeta } from "/app/apps/frontend/pages/logout.vue?macro=true";
import { default as maintenanceEQ8sX2IEXsMeta } from "/app/apps/frontend/pages/maintenance.vue?macro=true";
import { default as _91_46_46_46all_93FqEAZWE9t3Meta } from "/app/apps/frontend/pages/preview/cms/[...all].vue?macro=true";
import { default as _91_46_46_46all_93EFidBF2qa5Meta } from "/app/apps/frontend/pages/preview/draft/[...all].vue?macro=true";
import { default as registrationV1homtgbPMMeta } from "/app/apps/frontend/pages/registration.vue?macro=true";
import { default as resetcoFqLVtkPXMeta } from "/app/apps/frontend/pages/reset.vue?macro=true";
import { default as wishlistNH95n0xRyNMeta } from "/app/apps/frontend/pages/wishlist.vue?macro=true";
export default [
  {
    name: _91_46_46_46all_93a3xWq08JtuMeta?.name ?? "locale-all",
    path: _91_46_46_46all_93a3xWq08JtuMeta?.path ?? "/:locale/:all(.*)*",
    meta: _91_46_46_46all_93a3xWq08JtuMeta || {},
    alias: _91_46_46_46all_93a3xWq08JtuMeta?.alias || [],
    redirect: _91_46_46_46all_93a3xWq08JtuMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: addressguRCl4dvGPMeta?.name ?? "locale-account-address",
    path: addressguRCl4dvGPMeta?.path ?? "/:locale/account/address",
    meta: addressguRCl4dvGPMeta || {},
    alias: addressguRCl4dvGPMeta?.alias || [],
    redirect: addressguRCl4dvGPMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: index3tD6t8gxyLMeta?.name ?? "locale-account",
    path: index3tD6t8gxyLMeta?.path ?? "/:locale/account",
    meta: index3tD6t8gxyLMeta || {},
    alias: index3tD6t8gxyLMeta?.alias || [],
    redirect: index3tD6t8gxyLMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: orderUrWAIr8TRAMeta?.name ?? "locale-account-order",
    path: orderUrWAIr8TRAMeta?.path ?? "/:locale/account/order",
    meta: orderUrWAIr8TRAMeta || {},
    alias: orderUrWAIr8TRAMeta?.alias || [],
    redirect: orderUrWAIr8TRAMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: paymentMrp2vORYZrMeta?.name ?? "locale-account-payment",
    path: paymentMrp2vORYZrMeta?.path ?? "/:locale/account/payment",
    meta: paymentMrp2vORYZrMeta || {},
    alias: paymentMrp2vORYZrMeta?.alias || [],
    redirect: paymentMrp2vORYZrMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: profiletdkuPhsJWrMeta?.name ?? "locale-account-profile",
    path: profiletdkuPhsJWrMeta?.path ?? "/:locale/account/profile",
    meta: profiletdkuPhsJWrMeta || {},
    alias: profiletdkuPhsJWrMeta?.alias || [],
    redirect: profiletdkuPhsJWrMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: index3LsJlrAiTcMeta?.name ?? "locale-account-recover-password",
    path: index3LsJlrAiTcMeta?.path ?? "/:locale/account/recover/password",
    meta: index3LsJlrAiTcMeta || {},
    alias: index3LsJlrAiTcMeta?.alias || [],
    redirect: index3LsJlrAiTcMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/account/recover/password/index.vue").then(m => m.default || m)
  },
  {
    name: confirm6LNl4L8oUZMeta?.name ?? "locale-checkout-confirm",
    path: confirm6LNl4L8oUZMeta?.path ?? "/:locale/checkout/confirm",
    meta: confirm6LNl4L8oUZMeta || {},
    alias: confirm6LNl4L8oUZMeta?.alias || [],
    redirect: confirm6LNl4L8oUZMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/checkout/confirm.vue").then(m => m.default || m)
  },
  {
    name: registerutssanLaQwMeta?.name ?? "locale-checkout-register",
    path: registerutssanLaQwMeta?.path ?? "/:locale/checkout/register",
    meta: registerutssanLaQwMeta || {},
    alias: registerutssanLaQwMeta?.alias || [],
    redirect: registerutssanLaQwMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/checkout/register.vue").then(m => m.default || m)
  },
  {
    name: indexKHYIFtlm2mMeta?.name ?? "locale-checkout-success-id",
    path: indexKHYIFtlm2mMeta?.path ?? "/:locale/checkout/success/:id",
    meta: indexKHYIFtlm2mMeta || {},
    alias: indexKHYIFtlm2mMeta?.alias || [],
    redirect: indexKHYIFtlm2mMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/checkout/success/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexpj0li1Bm2eMeta?.name ?? "locale-checkout-unpaid-id",
    path: indexpj0li1Bm2eMeta?.path ?? "/:locale/checkout/unpaid/:id",
    meta: indexpj0li1Bm2eMeta || {},
    alias: indexpj0li1Bm2eMeta?.alias || [],
    redirect: indexpj0li1Bm2eMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/checkout/unpaid/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: filialfinderu4TVgYw9fhMeta?.name ?? "locale-filialfinder",
    path: filialfinderu4TVgYw9fhMeta?.path ?? "/:locale/filialfinder",
    meta: filialfinderu4TVgYw9fhMeta || {},
    alias: filialfinderu4TVgYw9fhMeta?.alias || [],
    redirect: filialfinderu4TVgYw9fhMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/filialfinder.vue").then(m => m.default || m)
  },
  {
    name: healthchecksVfnRSYgEDMeta?.name ?? "locale-healthcheck",
    path: healthchecksVfnRSYgEDMeta?.path ?? "/:locale/healthcheck",
    meta: healthchecksVfnRSYgEDMeta || {},
    alias: healthchecksVfnRSYgEDMeta?.alias || [],
    redirect: healthchecksVfnRSYgEDMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/healthcheck.vue").then(m => m.default || m)
  },
  {
    name: loginz5xTTqtYoVMeta?.name ?? "locale-login",
    path: loginz5xTTqtYoVMeta?.path ?? "/:locale/login",
    meta: loginz5xTTqtYoVMeta || {},
    alias: loginz5xTTqtYoVMeta?.alias || [],
    redirect: loginz5xTTqtYoVMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutg1aYmOMNDyMeta?.name ?? "locale-logout",
    path: logoutg1aYmOMNDyMeta?.path ?? "/:locale/logout",
    meta: logoutg1aYmOMNDyMeta || {},
    alias: logoutg1aYmOMNDyMeta?.alias || [],
    redirect: logoutg1aYmOMNDyMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: maintenanceEQ8sX2IEXsMeta?.name ?? "locale-maintenance",
    path: maintenanceEQ8sX2IEXsMeta?.path ?? "/:locale/maintenance",
    meta: maintenanceEQ8sX2IEXsMeta || {},
    alias: maintenanceEQ8sX2IEXsMeta?.alias || [],
    redirect: maintenanceEQ8sX2IEXsMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93FqEAZWE9t3Meta?.name ?? "locale-preview-cms-all",
    path: _91_46_46_46all_93FqEAZWE9t3Meta?.path ?? "/:locale/preview/cms/:all(.*)*",
    meta: _91_46_46_46all_93FqEAZWE9t3Meta || {},
    alias: _91_46_46_46all_93FqEAZWE9t3Meta?.alias || [],
    redirect: _91_46_46_46all_93FqEAZWE9t3Meta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/preview/cms/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93EFidBF2qa5Meta?.name ?? "locale-preview-draft-all",
    path: _91_46_46_46all_93EFidBF2qa5Meta?.path ?? "/:locale/preview/draft/:all(.*)*",
    meta: _91_46_46_46all_93EFidBF2qa5Meta || {},
    alias: _91_46_46_46all_93EFidBF2qa5Meta?.alias || [],
    redirect: _91_46_46_46all_93EFidBF2qa5Meta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/preview/draft/[...all].vue").then(m => m.default || m)
  },
  {
    name: registrationV1homtgbPMMeta?.name ?? "locale-registration",
    path: registrationV1homtgbPMMeta?.path ?? "/:locale/registration",
    meta: registrationV1homtgbPMMeta || {},
    alias: registrationV1homtgbPMMeta?.alias || [],
    redirect: registrationV1homtgbPMMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: resetcoFqLVtkPXMeta?.name ?? "locale-reset",
    path: resetcoFqLVtkPXMeta?.path ?? "/:locale/reset",
    meta: resetcoFqLVtkPXMeta || {},
    alias: resetcoFqLVtkPXMeta?.alias || [],
    redirect: resetcoFqLVtkPXMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/reset.vue").then(m => m.default || m)
  },
  {
    name: wishlistNH95n0xRyNMeta?.name ?? "locale-wishlist",
    path: wishlistNH95n0xRyNMeta?.path ?? "/:locale/wishlist",
    meta: wishlistNH95n0xRyNMeta || {},
    alias: wishlistNH95n0xRyNMeta?.alias || [],
    redirect: wishlistNH95n0xRyNMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93a3xWq08JtuMeta?.name ?? "locale-all",
    path: _91_46_46_46all_93a3xWq08JtuMeta?.path ?? "/:locale/:all(.*)*",
    meta: _91_46_46_46all_93a3xWq08JtuMeta || {},
    alias: _91_46_46_46all_93a3xWq08JtuMeta?.alias || [],
    redirect: _91_46_46_46all_93a3xWq08JtuMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: addressguRCl4dvGPMeta?.name ?? "locale-account-address",
    path: addressguRCl4dvGPMeta?.path ?? "/:locale/account/address",
    meta: addressguRCl4dvGPMeta || {},
    alias: addressguRCl4dvGPMeta?.alias || [],
    redirect: addressguRCl4dvGPMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: index3tD6t8gxyLMeta?.name ?? "locale-account",
    path: index3tD6t8gxyLMeta?.path ?? "/:locale/account",
    meta: index3tD6t8gxyLMeta || {},
    alias: index3tD6t8gxyLMeta?.alias || [],
    redirect: index3tD6t8gxyLMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: orderUrWAIr8TRAMeta?.name ?? "locale-account-order",
    path: orderUrWAIr8TRAMeta?.path ?? "/:locale/account/order",
    meta: orderUrWAIr8TRAMeta || {},
    alias: orderUrWAIr8TRAMeta?.alias || [],
    redirect: orderUrWAIr8TRAMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: paymentMrp2vORYZrMeta?.name ?? "locale-account-payment",
    path: paymentMrp2vORYZrMeta?.path ?? "/:locale/account/payment",
    meta: paymentMrp2vORYZrMeta || {},
    alias: paymentMrp2vORYZrMeta?.alias || [],
    redirect: paymentMrp2vORYZrMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: profiletdkuPhsJWrMeta?.name ?? "locale-account-profile",
    path: profiletdkuPhsJWrMeta?.path ?? "/:locale/account/profile",
    meta: profiletdkuPhsJWrMeta || {},
    alias: profiletdkuPhsJWrMeta?.alias || [],
    redirect: profiletdkuPhsJWrMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: index3LsJlrAiTcMeta?.name ?? "locale-account-recover-password",
    path: index3LsJlrAiTcMeta?.path ?? "/:locale/account/recover/password",
    meta: index3LsJlrAiTcMeta || {},
    alias: index3LsJlrAiTcMeta?.alias || [],
    redirect: index3LsJlrAiTcMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/account/recover/password/index.vue").then(m => m.default || m)
  },
  {
    name: confirm6LNl4L8oUZMeta?.name ?? "locale-checkout-confirm",
    path: confirm6LNl4L8oUZMeta?.path ?? "/:locale/checkout/confirm",
    meta: confirm6LNl4L8oUZMeta || {},
    alias: confirm6LNl4L8oUZMeta?.alias || [],
    redirect: confirm6LNl4L8oUZMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/checkout/confirm.vue").then(m => m.default || m)
  },
  {
    name: registerutssanLaQwMeta?.name ?? "locale-checkout-register",
    path: registerutssanLaQwMeta?.path ?? "/:locale/checkout/register",
    meta: registerutssanLaQwMeta || {},
    alias: registerutssanLaQwMeta?.alias || [],
    redirect: registerutssanLaQwMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/checkout/register.vue").then(m => m.default || m)
  },
  {
    name: indexKHYIFtlm2mMeta?.name ?? "locale-checkout-success-id",
    path: indexKHYIFtlm2mMeta?.path ?? "/:locale/checkout/success/:id",
    meta: indexKHYIFtlm2mMeta || {},
    alias: indexKHYIFtlm2mMeta?.alias || [],
    redirect: indexKHYIFtlm2mMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/checkout/success/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexpj0li1Bm2eMeta?.name ?? "locale-checkout-unpaid-id",
    path: indexpj0li1Bm2eMeta?.path ?? "/:locale/checkout/unpaid/:id",
    meta: indexpj0li1Bm2eMeta || {},
    alias: indexpj0li1Bm2eMeta?.alias || [],
    redirect: indexpj0li1Bm2eMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/checkout/unpaid/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: filialfinderu4TVgYw9fhMeta?.name ?? "locale-filialfinder",
    path: filialfinderu4TVgYw9fhMeta?.path ?? "/:locale/filialfinder",
    meta: filialfinderu4TVgYw9fhMeta || {},
    alias: filialfinderu4TVgYw9fhMeta?.alias || [],
    redirect: filialfinderu4TVgYw9fhMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/filialfinder.vue").then(m => m.default || m)
  },
  {
    name: healthchecksVfnRSYgEDMeta?.name ?? "locale-healthcheck",
    path: healthchecksVfnRSYgEDMeta?.path ?? "/:locale/healthcheck",
    meta: healthchecksVfnRSYgEDMeta || {},
    alias: healthchecksVfnRSYgEDMeta?.alias || [],
    redirect: healthchecksVfnRSYgEDMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/healthcheck.vue").then(m => m.default || m)
  },
  {
    name: loginz5xTTqtYoVMeta?.name ?? "locale-login",
    path: loginz5xTTqtYoVMeta?.path ?? "/:locale/login",
    meta: loginz5xTTqtYoVMeta || {},
    alias: loginz5xTTqtYoVMeta?.alias || [],
    redirect: loginz5xTTqtYoVMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutg1aYmOMNDyMeta?.name ?? "locale-logout",
    path: logoutg1aYmOMNDyMeta?.path ?? "/:locale/logout",
    meta: logoutg1aYmOMNDyMeta || {},
    alias: logoutg1aYmOMNDyMeta?.alias || [],
    redirect: logoutg1aYmOMNDyMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: maintenanceEQ8sX2IEXsMeta?.name ?? "locale-maintenance",
    path: maintenanceEQ8sX2IEXsMeta?.path ?? "/:locale/maintenance",
    meta: maintenanceEQ8sX2IEXsMeta || {},
    alias: maintenanceEQ8sX2IEXsMeta?.alias || [],
    redirect: maintenanceEQ8sX2IEXsMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93FqEAZWE9t3Meta?.name ?? "locale-preview-cms-all",
    path: _91_46_46_46all_93FqEAZWE9t3Meta?.path ?? "/:locale/preview/cms/:all(.*)*",
    meta: _91_46_46_46all_93FqEAZWE9t3Meta || {},
    alias: _91_46_46_46all_93FqEAZWE9t3Meta?.alias || [],
    redirect: _91_46_46_46all_93FqEAZWE9t3Meta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/preview/cms/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93EFidBF2qa5Meta?.name ?? "locale-preview-draft-all",
    path: _91_46_46_46all_93EFidBF2qa5Meta?.path ?? "/:locale/preview/draft/:all(.*)*",
    meta: _91_46_46_46all_93EFidBF2qa5Meta || {},
    alias: _91_46_46_46all_93EFidBF2qa5Meta?.alias || [],
    redirect: _91_46_46_46all_93EFidBF2qa5Meta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/preview/draft/[...all].vue").then(m => m.default || m)
  },
  {
    name: registrationV1homtgbPMMeta?.name ?? "locale-registration",
    path: registrationV1homtgbPMMeta?.path ?? "/:locale/registration",
    meta: registrationV1homtgbPMMeta || {},
    alias: registrationV1homtgbPMMeta?.alias || [],
    redirect: registrationV1homtgbPMMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: resetcoFqLVtkPXMeta?.name ?? "locale-reset",
    path: resetcoFqLVtkPXMeta?.path ?? "/:locale/reset",
    meta: resetcoFqLVtkPXMeta || {},
    alias: resetcoFqLVtkPXMeta?.alias || [],
    redirect: resetcoFqLVtkPXMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/reset.vue").then(m => m.default || m)
  },
  {
    name: wishlistNH95n0xRyNMeta?.name ?? "locale-wishlist",
    path: wishlistNH95n0xRyNMeta?.path ?? "/:locale/wishlist",
    meta: wishlistNH95n0xRyNMeta || {},
    alias: wishlistNH95n0xRyNMeta?.alias || [],
    redirect: wishlistNH95n0xRyNMeta?.redirect || undefined,
    component: () => import("/app/apps/frontend/pages/wishlist.vue").then(m => m.default || m)
  }
]
import { defineAsyncComponent } from 'vue'
import { defineNuxtPlugin } from '#app/nuxt'

const components = {
  EventListener: defineAsyncComponent(() => import("/app/apps/frontend/components/EventListener.vue" /* webpackChunkName: "components/event-listener" */).then(c => c.default || c)),
  FrontendDetailPage: defineAsyncComponent(() => import("/app/apps/frontend/components/FrontendDetailPage.vue" /* webpackChunkName: "components/frontend-detail-page" */).then(c => c.default || c)),
  FrontendNavigationPage: defineAsyncComponent(() => import("/app/apps/frontend/components/FrontendNavigationPage.vue" /* webpackChunkName: "components/frontend-navigation-page" */).then(c => c.default || c)),
  FrontendPublisherPreviewPage: defineAsyncComponent(() => import("/app/apps/frontend/components/FrontendPublisherPreviewPage.vue" /* webpackChunkName: "components/frontend-publisher-preview-page" */).then(c => c.default || c)),
  LoadingIndicator: defineAsyncComponent(() => import("/app/apps/frontend/components/LoadingIndicator.vue" /* webpackChunkName: "components/loading-indicator" */).then(c => c.default || c)),
  LocaleRouterLink: defineAsyncComponent(() => import("/app/apps/frontend/components/LocaleRouterLink.vue" /* webpackChunkName: "components/locale-router-link" */).then(c => c.default || c)),
  MetaPagination: defineAsyncComponent(() => import("/app/apps/frontend/components/MetaPagination.vue" /* webpackChunkName: "components/meta-pagination" */).then(c => c.default || c)),
  CmsGenericBlock: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/CmsGenericBlock.vue" /* webpackChunkName: "components/cms-generic-block" */).then(c => c.default || c)),
  CmsGenericElement: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/CmsGenericElement.vue" /* webpackChunkName: "components/cms-generic-element" */).then(c => c.default || c)),
  CmsPage: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/CmsPage.vue" /* webpackChunkName: "components/cms-page" */).then(c => c.default || c)),
  CmsSectionDefault: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/CmsSectionDefault.vue" /* webpackChunkName: "components/cms-section-default" */).then(c => c.default || c)),
  CmsBlockCategoryNavigation: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockCategoryNavigation.vue" /* webpackChunkName: "components/cms-block-category-navigation" */).then(c => c.default || c)),
  CmsBlockCustomForm: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockCustomForm.vue" /* webpackChunkName: "components/cms-block-custom-form" */).then(c => c.default || c)),
  CmsBlockForm: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockForm.vue" /* webpackChunkName: "components/cms-block-form" */).then(c => c.default || c)),
  CmsBlockHtmlCode: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockHtmlCode.vue" /* webpackChunkName: "components/cms-block-html-code" */).then(c => c.default || c)),
  CmsBlockImage: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockImage.vue" /* webpackChunkName: "components/cms-block-image" */).then(c => c.default || c)),
  CmsBlockImageHighlightRow: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockImageHighlightRow.vue" /* webpackChunkName: "components/cms-block-image-highlight-row" */).then(c => c.default || c)),
  CmsBlockImageSimpleGrid: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockImageSimpleGrid.vue" /* webpackChunkName: "components/cms-block-image-simple-grid" */).then(c => c.default || c)),
  CmsBlockImageText: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockImageText.vue" /* webpackChunkName: "components/cms-block-image-text" */).then(c => c.default || c)),
  CmsBlockImageTextCover: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockImageTextCover.vue" /* webpackChunkName: "components/cms-block-image-text-cover" */).then(c => c.default || c)),
  CmsBlockImageTextRow: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockImageTextRow.vue" /* webpackChunkName: "components/cms-block-image-text-row" */).then(c => c.default || c)),
  CmsBlockImageThreeColumn: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockImageThreeColumn.vue" /* webpackChunkName: "components/cms-block-image-three-column" */).then(c => c.default || c)),
  CmsBlockImageThreeCover: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockImageThreeCover.vue" /* webpackChunkName: "components/cms-block-image-three-cover" */).then(c => c.default || c)),
  CmsBlockImageTwoColumn: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockImageTwoColumn.vue" /* webpackChunkName: "components/cms-block-image-two-column" */).then(c => c.default || c)),
  CmsBlockLnBannerSlider: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockLnBannerSlider.vue" /* webpackChunkName: "components/cms-block-ln-banner-slider" */).then(c => c.default || c)),
  CmsBlockLnBannerTwoColumns: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockLnBannerTwoColumns.vue" /* webpackChunkName: "components/cms-block-ln-banner-two-columns" */).then(c => c.default || c)),
  CmsBlockLnBannerTwoColumnsDouble: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockLnBannerTwoColumnsDouble.vue" /* webpackChunkName: "components/cms-block-ln-banner-two-columns-double" */).then(c => c.default || c)),
  CmsBlockLnCarousel: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockLnCarousel.vue" /* webpackChunkName: "components/cms-block-ln-carousel" */).then(c => c.default || c)),
  CmsBlockLnCenterImageText: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockLnCenterImageText.vue" /* webpackChunkName: "components/cms-block-ln-center-image-text" */).then(c => c.default || c)),
  CmsBlockLnEcrmForm: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockLnEcrmForm.vue" /* webpackChunkName: "components/cms-block-ln-ecrm-form" */).then(c => c.default || c)),
  CmsBlockLnHighlightSlider: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockLnHighlightSlider.vue" /* webpackChunkName: "components/cms-block-ln-highlight-slider" */).then(c => c.default || c)),
  CmsBlockLnInstagram: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockLnInstagram.vue" /* webpackChunkName: "components/cms-block-ln-instagram" */).then(c => c.default || c)),
  CmsBlockLnSelfHostedVideo: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockLnSelfHostedVideo.vue" /* webpackChunkName: "components/cms-block-ln-self-hosted-video" */).then(c => c.default || c)),
  CmsBlockLnSizeFinder: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockLnSizeFinder.vue" /* webpackChunkName: "components/cms-block-ln-size-finder" */).then(c => c.default || c)),
  CmsBlockProductListing: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockProductListing.vue" /* webpackChunkName: "components/cms-block-product-listing" */).then(c => c.default || c)),
  CmsBlockProductSlider: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockProductSlider.vue" /* webpackChunkName: "components/cms-block-product-slider" */).then(c => c.default || c)),
  CmsBlockSidebarFilter: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockSidebarFilter.vue" /* webpackChunkName: "components/cms-block-sidebar-filter" */).then(c => c.default || c)),
  CmsBlockText: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockText.vue" /* webpackChunkName: "components/cms-block-text" */).then(c => c.default || c)),
  CmsBlockTextHero: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockTextHero.vue" /* webpackChunkName: "components/cms-block-text-hero" */).then(c => c.default || c)),
  CmsBlockTextOnImage: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockTextOnImage.vue" /* webpackChunkName: "components/cms-block-text-on-image" */).then(c => c.default || c)),
  CmsBlockTextTeaser: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockTextTeaser.vue" /* webpackChunkName: "components/cms-block-text-teaser" */).then(c => c.default || c)),
  CmsBlockTextTeaserSection: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockTextTeaserSection.vue" /* webpackChunkName: "components/cms-block-text-teaser-section" */).then(c => c.default || c)),
  CmsBlockTextTwoColumn: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockTextTwoColumn.vue" /* webpackChunkName: "components/cms-block-text-two-column" */).then(c => c.default || c)),
  CmsBlockYoutubeVideo: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/block/CmsBlockYoutubeVideo.vue" /* webpackChunkName: "components/cms-block-youtube-video" */).then(c => c.default || c)),
  CmsElementCategoryNavigation: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/element/CmsElementCategoryNavigation.vue" /* webpackChunkName: "components/cms-element-category-navigation" */).then(c => c.default || c)),
  CmsElementCustomForm: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/element/CmsElementCustomForm.vue" /* webpackChunkName: "components/cms-element-custom-form" */).then(c => c.default || c)),
  CmsElementForm: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/element/CmsElementForm.vue" /* webpackChunkName: "components/cms-element-form" */).then(c => c.default || c)),
  CmsElementHtmlCode: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/element/CmsElementHtmlCode.vue" /* webpackChunkName: "components/cms-element-html-code" */).then(c => c.default || c)),
  CmsElementImage: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/element/CmsElementImage.vue" /* webpackChunkName: "components/cms-element-image" */).then(c => c.default || c)),
  CmsElementLnBanner: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/element/CmsElementLnBanner.vue" /* webpackChunkName: "components/cms-element-ln-banner" */).then(c => c.default || c)),
  CmsElementLnBannerSlider: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/element/CmsElementLnBannerSlider.vue" /* webpackChunkName: "components/cms-element-ln-banner-slider" */).then(c => c.default || c)),
  CmsElementLnCarousel: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/element/CmsElementLnCarousel.vue" /* webpackChunkName: "components/cms-element-ln-carousel" */).then(c => c.default || c)),
  CmsElementLnCenterImageText: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/element/CmsElementLnCenterImageText.vue" /* webpackChunkName: "components/cms-element-ln-center-image-text" */).then(c => c.default || c)),
  CmsElementLnEcrmForm: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/element/CmsElementLnEcrmForm.vue" /* webpackChunkName: "components/cms-element-ln-ecrm-form" */).then(c => c.default || c)),
  CmsElementLnHighlightSlider: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/element/CmsElementLnHighlightSlider.vue" /* webpackChunkName: "components/cms-element-ln-highlight-slider" */).then(c => c.default || c)),
  CmsElementLnInstagram: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/element/CmsElementLnInstagram.vue" /* webpackChunkName: "components/cms-element-ln-instagram" */).then(c => c.default || c)),
  CmsElementLnSelfHostedVideo: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/element/CmsElementLnSelfHostedVideo.vue" /* webpackChunkName: "components/cms-element-ln-self-hosted-video" */).then(c => c.default || c)),
  CmsElementLnSizeFinder: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/element/CmsElementLnSizeFinder.vue" /* webpackChunkName: "components/cms-element-ln-size-finder" */).then(c => c.default || c)),
  CmsElementProductListing: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/element/CmsElementProductListing.vue" /* webpackChunkName: "components/cms-element-product-listing" */).then(c => c.default || c)),
  CmsElementProductSlider: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/element/CmsElementProductSlider.vue" /* webpackChunkName: "components/cms-element-product-slider" */).then(c => c.default || c)),
  CmsElementSidebarFilter: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/element/CmsElementSidebarFilter.vue" /* webpackChunkName: "components/cms-element-sidebar-filter" */).then(c => c.default || c)),
  CmsElementText: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/element/CmsElementText.vue" /* webpackChunkName: "components/cms-element-text" */).then(c => c.default || c)),
  CmsElementYoutubeVideo: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/element/CmsElementYoutubeVideo.vue" /* webpackChunkName: "components/cms-element-youtube-video" */).then(c => c.default || c)),
  CmsSectionSidebar: defineAsyncComponent(() => import("/app/apps/frontend/components/cms/sections/CmsSectionSidebar.vue" /* webpackChunkName: "components/cms-section-sidebar" */).then(c => c.default || c)),
  ErrorsRoutingNotFound: defineAsyncComponent(() => import("/app/apps/frontend/components/errors/RoutingNotFound.vue" /* webpackChunkName: "components/errors-routing-not-found" */).then(c => c.default || c)),
  ErrorsRoutingNotFoundPlain: defineAsyncComponent(() => import("/app/apps/frontend/components/errors/RoutingNotFoundPlain.vue" /* webpackChunkName: "components/errors-routing-not-found-plain" */).then(c => c.default || c)),
  LayoutBreadcrumbs: defineAsyncComponent(() => import("/app/apps/frontend/components/layout/LayoutBreadcrumbs.vue" /* webpackChunkName: "components/layout-breadcrumbs" */).then(c => c.default || c)),
  LayoutFooter: defineAsyncComponent(() => import("/app/apps/frontend/components/layout/LayoutFooter.vue" /* webpackChunkName: "components/layout-footer" */).then(c => c.default || c)),
  LayoutHeader: defineAsyncComponent(() => import("/app/apps/frontend/components/layout/LayoutHeader.vue" /* webpackChunkName: "components/layout-header" */).then(c => c.default || c)),
  LayoutNotification: defineAsyncComponent(() => import("/app/apps/frontend/components/layout/LayoutNotification.vue" /* webpackChunkName: "components/layout-notification" */).then(c => c.default || c)),
  LayoutNotifications: defineAsyncComponent(() => import("/app/apps/frontend/components/layout/LayoutNotifications.vue" /* webpackChunkName: "components/layout-notifications" */).then(c => c.default || c)),
  LayoutSideMenu: defineAsyncComponent(() => import("/app/apps/frontend/components/layout/LayoutSideMenu.vue" /* webpackChunkName: "components/layout-side-menu" */).then(c => c.default || c)),
  WrappersApiComposableWrapper: defineAsyncComponent(() => import("/app/apps/frontend/components/wrappers/ApiComposableWrapper.vue" /* webpackChunkName: "components/wrappers-api-composable-wrapper" */).then(c => c.default || c)),
  ZoomOnHoover: defineAsyncComponent(() => import("/app/apps/frontend/components/zoom-on-hoover.js" /* webpackChunkName: "components/zoom-on-hoover" */).then(c => c.default || c)),
  Accordion: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/accordion" */).then(c => c['Accordion'])),
  AccountSidebar: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/account-sidebar" */).then(c => c['AccountSidebar'])),
  AddressForm: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/address-form" */).then(c => c['AddressForm'])),
  AddressInfo: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/address-info" */).then(c => c['AddressInfo'])),
  AddressSuggestionSelect: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/address-suggestion-select" */).then(c => c['AddressSuggestionSelect'])),
  AdvantagesList: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/advantages-list" */).then(c => c['AdvantagesList'])),
  AssignNewPasswordForm: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/assign-new-password-form" */).then(c => c['AssignNewPasswordForm'])),
  Badge: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/badge" */).then(c => c['Badge'])),
  Banner: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/banner" */).then(c => c['Banner'])),
  BannerSlider: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/banner-slider" */).then(c => c['BannerSlider'])),
  Bar: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/bar" */).then(c => c['Bar'])),
  BirthdayPicker: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/birthday-picker" */).then(c => c['BirthdayPicker'])),
  Breadcrumbs: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/breadcrumbs" */).then(c => c['Breadcrumbs'])),
  Button: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/button" */).then(c => c['Button'])),
  ButtonWithIcon: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/button-with-icon" */).then(c => c['ButtonWithIcon'])),
  Carousel: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/carousel" */).then(c => c['Carousel'])),
  CardContent: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/card-content" */).then(c => c['CardContent'])),
  CategoryNavigationMenu: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/category-navigation-menu" */).then(c => c['CategoryNavigationMenu'])),
  Checkbox: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/checkbox" */).then(c => c['Checkbox'])),
  Checkout: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/checkout" */).then(c => c['Checkout'])),
  CheckoutAddressModal: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/checkout-address-modal" */).then(c => c['CheckoutAddressModal'])),
  CheckoutProcessBar: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/checkout-process-bar" */).then(c => c['CheckoutProcessBar'])),
  CheckoutSelectAddress: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/checkout-select-address" */).then(c => c['CheckoutSelectAddress'])),
  CenteredImageText: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/centered-image-text" */).then(c => c['CenteredImageText'])),
  ContentPages: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/content-pages" */).then(c => c['ContentPages'])),
  DynamicForm: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/dynamic-form" */).then(c => c['DynamicForm'])),
  DynamicFormGroup: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/dynamic-form-group" */).then(c => c['DynamicFormGroup'])),
  Error: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/error" */).then(c => c['Error'])),
  FilterProperty: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/filter-property" */).then(c => c['FilterProperty'])),
  Footer: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/footer" */).then(c => c['Footer'])),
  FooterBottomSection: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/footer-bottom-section" */).then(c => c['FooterBottomSection'])),
  FooterCategory: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/footer-category" */).then(c => c['FooterCategory'])),
  FooterDropdown: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/footer-dropdown" */).then(c => c['FooterDropdown'])),
  FooterLanguageSwitcher: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/footer-language-switcher" */).then(c => c['FooterLanguageSwitcher'])),
  FooterNewsletterForm: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/footer-newsletter-form" */).then(c => c['FooterNewsletterForm'])),
  FooterPaymentMethods: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/footer-payment-methods" */).then(c => c['FooterPaymentMethods'])),
  FooterSocialMedia: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/footer-social-media" */).then(c => c['FooterSocialMedia'])),
  ForgotPasswordModal: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/forgot-password-modal" */).then(c => c['ForgotPasswordModal'])),
  Header: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/header" */).then(c => c['Header'])),
  Headline: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/headline" */).then(c => c['Headline'])),
  HighlightSlider: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/highlight-slider" */).then(c => c['HighlightSlider'])),
  HtmlCode: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/html-code" */).then(c => c['HtmlCode'])),
  Icon: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/icon" */).then(c => c['Icon'])),
  IconSprite: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/icon-sprite" */).then(c => c['IconSprite'])),
  Iframe: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/iframe" */).then(c => c['Iframe'])),
  Image: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/image" */).then(c => c['Image'])),
  Instagram: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/instagram" */).then(c => c['Instagram'])),
  KeenSlider: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/keen-slider" */).then(c => c['KeenSlider'])),
  Listing: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/listing" */).then(c => c['Listing'])),
  ListingPanel: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/listing-panel" */).then(c => c['ListingPanel'])),
  Loader: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/loader" */).then(c => c['Loader'])),
  LoginForm: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/login-form" */).then(c => c['LoginForm'])),
  Logo: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/logo" */).then(c => c['Logo'])),
  MegaMenu: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/mega-menu" */).then(c => c['MegaMenu'])),
  MinimalFooter: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/minimal-footer" */).then(c => c['MinimalFooter'])),
  MobileMenu: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/mobile-menu" */).then(c => c['MobileMenu'])),
  MobileNavigation: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/mobile-navigation" */).then(c => c['MobileNavigation'])),
  MobilePicker: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/mobile-picker" */).then(c => c['MobilePicker'])),
  MobileSearch: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/mobile-search" */).then(c => c['MobileSearch'])),
  Modal: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/modal" */).then(c => c['Modal'])),
  NewPasswordForm: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/new-password-form" */).then(c => c['NewPasswordForm'])),
  NewsletterEmailForm: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/newsletter-email-form" */).then(c => c['NewsletterEmailForm'])),
  NewsletterWidgetForm: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/newsletter-widget-form" */).then(c => c['NewsletterWidgetForm'])),
  NewsletterModal: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/newsletter-modal" */).then(c => c['NewsletterModal'])),
  Notification: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/notification" */).then(c => c['Notification'])),
  OffcanvasCart: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/offcanvas-cart" */).then(c => c['OffcanvasCart'])),
  OcProductFilterSidebar: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/oc-product-filter-sidebar" */).then(c => c['OcProductFilterSidebar'])),
  OrderSuccess: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/order-success" */).then(c => c['OrderSuccess'])),
  OrderSummaryTable: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/order-summary-table" */).then(c => c['OrderSummaryTable'])),
  PaymentMethods: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/payment-methods" */).then(c => c['PaymentMethods'])),
  Price: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/price" */).then(c => c['Price'])),
  PriceRange: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/price-range" */).then(c => c['PriceRange'])),
  ProductCard: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/product-card" */).then(c => c['ProductCard'])),
  ProductCardCart: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/product-card-cart" */).then(c => c['ProductCardCart'])),
  ProductCardPicture: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/product-card-picture" */).then(c => c['ProductCardPicture'])),
  ProductDetails: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/product-details" */).then(c => c['ProductDetails'])),
  ProductDetailsColorVariants: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/product-details-color-variants" */).then(c => c['ProductDetailsColorVariants'])),
  ProductGallery: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/product-gallery" */).then(c => c['ProductGallery'])),
  ProductGalleryMobile: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/product-gallery-mobile" */).then(c => c['ProductGalleryMobile'])),
  ProductGalleryWrapper: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/product-gallery-wrapper" */).then(c => c['ProductGalleryWrapper'])),
  ProductHeading: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/product-heading" */).then(c => c['ProductHeading'])),
  ProductSlider: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/product-slider" */).then(c => c['ProductSlider'])),
  ProfileForm: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/profile-form" */).then(c => c['ProfileForm'])),
  Property: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/property" */).then(c => c['Property'])),
  PropertySelect: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/property-select" */).then(c => c['PropertySelect'])),
  PurchaseInfo: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/purchase-info" */).then(c => c['PurchaseInfo'])),
  PurchaseOptions: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/purchase-options" */).then(c => c['PurchaseOptions'])),
  QuantityControl: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/quantity-control" */).then(c => c['QuantityControl'])),
  Radio: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/radio" */).then(c => c['Radio'])),
  Range: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/range" */).then(c => c['Range'])),
  RegistrationForm: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/registration-form" */).then(c => c['RegistrationForm'])),
  RegistrationModal: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/registration-modal" */).then(c => c['RegistrationModal'])),
  ResetPasswordForm: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/reset-password-form" */).then(c => c['ResetPasswordForm'])),
  Select: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/select" */).then(c => c['Select'])),
  SelectLanguage: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/select-language" */).then(c => c['SelectLanguage'])),
  SelectProperty: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/select-property" */).then(c => c['SelectProperty'])),
  SelfhostedVideo: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/selfhosted-video" */).then(c => c['SelfhostedVideo'])),
  SidebarOrderSummary: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/sidebar-order-summary" */).then(c => c['SidebarOrderSummary'])),
  SizeChart: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/size-chart" */).then(c => c['SizeChart'])),
  SizeFinder: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/size-finder" */).then(c => c['SizeFinder'])),
  Text: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/text" */).then(c => c['Text'])),
  Textarea: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/textarea" */).then(c => c['Textarea'])),
  ToggleRegistrationModal: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/toggle-registration-modal" */).then(c => c['ToggleRegistrationModal'])),
  LanguageRoutingModal: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/language-routing-modal" */).then(c => c['LanguageRoutingModal'])),
  Tooltip: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/tooltip" */).then(c => c['Tooltip'])),
  Topbar: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/topbar" */).then(c => c['Topbar'])),
  TopNavigation: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/top-navigation" */).then(c => c['TopNavigation'])),
  Totals: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/totals" */).then(c => c['Totals'])),
  VoucherCard: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/voucher-card" */).then(c => c['VoucherCard'])),
  VoucherForm: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/voucher-form" */).then(c => c['VoucherForm'])),
  YoutubeVideo: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/youtube-video" */).then(c => c['YoutubeVideo'])),
  ProfilePage: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/profile-page" */).then(c => c['ProfilePage'])),
  AddressPage: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/address-page" */).then(c => c['AddressPage'])),
  PaymentPage: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/payment-page" */).then(c => c['PaymentPage'])),
  OrdersPage: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/orders-page" */).then(c => c['OrdersPage'])),
  Account: defineAsyncComponent(() => import("@upa/design-system" /* webpackChunkName: "components/account" */).then(c => c['Account']))
}

export default defineNuxtPlugin(nuxtApp => {
  for (const name in components) {
    nuxtApp.vueApp.component(name, components[name])
    nuxtApp.vueApp.component('Lazy' + name, components[name])
  }
})

import * as Sentry from '@sentry/vue';
import { Scope } from '@sentry/types';

interface UseSentryReturn {
  captureException: (error: unknown, type?: string) => void;
}

export const useSentry = (): UseSentryReturn => {
  const isClient = process.client;

  const captureException = async (
    error: unknown,
    type = 'default'
  ): Promise<void> => {
    const SentryInstance = isClient ? Sentry : null;

    if (SentryInstance) {
      try {
        SentryInstance.withScope((scope: Scope) => {
          scope.setTag('app-type', type);
          scope.setLevel('warning');
          SentryInstance.captureException(error);
        });
      } catch (e) {
        console.error(`Failed to capture exception in Sentry: ${e}`);
      }
    }
  };

  return {
    captureException,
  };
};
